<template>
  <GModal name="modal" class="modal modal-confirm-cart">
    <div slot="header">
      <div class="modal-title">
        <div class="text-red">Batalkan pesanan?</div>
        <div class="text">Ada {{ totalQty }} produk di keranjang belanjamu. Yakin batal?</div>
      </div>
      <!-- <button type="button" class="close" @click.prevent="close"></button> -->
    </div>
    <div slot="body">
      <div class="continue-shopping" @click="goToOrder">
        <span>Kembali</span>
      </div>
      <div class="cancel" @click="cancel">
        <span>Batal</span>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalConfirmExit',
  props: {
    totalQty: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    goToOrder() {
      this.$emit('close');
    },
    cancel() {
      this.$emit('confirm');
    },
  },
};
</script>
